import React from "react"
import { RadioGroup, Radio } from "@blueprintjs/core"
import { ReactComponent as Happy } from "../../../assets/icons/icon-happy.svg"
import { ReactComponent as Sceptic } from "../../../assets/icons/icon-sceptic.svg"
import { ReactComponent as Sad } from "../../../assets/icons/icon-sad.svg"

import styles from "./valoration-input.module.scss"

const RatingInput = props => {
  return (
    <RadioGroup className={styles.radioGroup} inline {...props}>
      <Radio className={styles.radio} label={<Sad className={styles.radioIcon} />} value="-1" />
      <Radio className={styles.radio} label={<Sceptic className={styles.radioIcon} />} value="0" />
      <Radio className={styles.radio} label={<Happy className={styles.radioIcon} />} value="1" />
    </RadioGroup>
  )
}

export default RatingInput
