import React from "react"
// import RestaurantMainInfo from "common/components/restaurants/RestaurantMainInfo"
// import PlaceImage from "common/components/PlaceImage"
import RestautaurantItem from "common/components/restaurants/RestaurantItem"
import styles from "./restaurants-map.module.scss"

const RestaurantPopup = ({ restaurant }) => {
  return (
    <div className={styles.restaurantPopup}>
      <RestautaurantItem
        key={restaurant.data.place_id}
        place_id={restaurant.data.place_id}
        photos={restaurant.data.photos}
        name={restaurant.data.name}
        rating={restaurant.rating}
        external_rating={restaurant.data.rating}
        external_ratings_total={restaurant.data.user_ratings_total}
        price_level={restaurant.data.price_level}
        distance={restaurant.data.distance}
        vicinity={restaurant.data.vicinity}
        opening_hours={restaurant.data.opening_hours}
        visited={restaurant.visited}
        saved={restaurant.saved}
        visitedAt={restaurant.visitedAt}
        compact={true}
        notes={restaurant.notes}
        pushTop={true}
      />
    </div>
  )
}

export default RestaurantPopup
