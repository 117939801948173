import React from "react"
import HomePageView from "./HomePageView"

// -------------------------
// ui component

const HomePage = () => {
  return <HomePageView />
}

export default HomePage
