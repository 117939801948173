import axios from "axios"
import { currentUserToken } from "api/cognito"
import { API_URL } from "config/settings"

export async function registerUser(userAttributes) {
  // this is dirty hack to make the backend aware of the cognito user attributes
  const userToken = await currentUserToken()
  const response = await axios.post("/users", userAttributes, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function fetchUsersList() {
  const userToken = await currentUserToken()
  const response = await axios.get("/users", {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function fetchUserQueue(userId) {
  const userToken = await currentUserToken()
  const response = await axios.get(`/users/${userId}/queue`, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}
