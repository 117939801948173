import React from "react"
import RestautaurantItem from "./RestaurantItem"
import cx from "classnames"
import useScrollRevealEffect from "../../../hooks/effects/useScrollRevealEffect"

import styles from "./restaurants.module.scss"

const Restaurants = ({ restaurants, className }) => {
  const sectionRef = useScrollRevealEffect()
  return (
    <div className={cx(className, styles.restaurants)} ref={sectionRef}>
      {restaurants.map((item, i) => (
        <RestautaurantItem
          key={item.data.place_id}
          place_id={item.data.place_id}
          photos={item.data.photos}
          name={item.data.name}
          rating={item.rating}
          external_rating={item.data.rating}
          external_ratings_total={item.data.user_ratings_total}
          price_level={item.data.price_level}
          distance={item.data.distance}
          vicinity={item.data.vicinity}
          opening_hours={item.data.opening_hours}
          visited={item.visited}
          saved={item.saved}
          visitedAt={item.visitedAt}
          notes={item.notes}
          pushTop={true}
        />
      ))}
    </div>
  )
}

export default Restaurants
