import React from "react"
import { Intent } from "@blueprintjs/core"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Toaster from "features/toasterService/Toaster"
import PasswordResetPageView from "./PasswordResetPageView"

import { resetPasswordAction } from "./userAuthSlice"

// -------------------------
// form logic

const initialValues = {
  email: "",
  code: "",
  password: "",
  "confirm-password": "",
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(resetPasswordAction(values.email, values.code, values.password))
      Toaster.show({
        icon: "key",
        intent: Intent.SUCCESS,
        message: "Password succesufully changed.",
        timeout: 1000,
      })
      history.push("/auth/login")
    } catch (e) {
      Toaster.show({
        icon: "error",
        intent: Intent.DANGER,
        message: e.message,
        timeout: 1000,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordResetPage = () => {
  const handleSubmit = useHandleSubmit()
  return <PasswordResetPageView initialValues={initialValues} onSubmit={handleSubmit} />
}

export default PasswordResetPage
