import React from "react"
import RestaurantMainInfo from "common/components/restaurants/RestaurantMainInfo"
import cx from "classnames"
import { Link, useLocation } from "react-router-dom"
import defaultImage from "../../../assets/images/queue.png"
import UserRating from "common/components/user-rating/UserRating"
import { isEmpty } from "lodash"
import { Icon } from "@blueprintjs/core"
import DetailCarousel from "common/components/carousel/DetailCarousel"

import styles from "./restaurants.module.scss"

const RestaurantItem = ({
  place_id,
  photos,
  name,
  rating,
  external_rating,
  external_ratings_total,
  price_level,
  distance,
  vicinity,
  visited,
  saved,
  visitedAt,
  ref,
  notes,
  compact,
  pushTop,
}) => {
  const location = useLocation()

  return (
    <Link
      ref={ref}
      className={cx(styles.restaurantCard, {
        [styles.isVisited]: visited,
        [styles.isSaved]: saved,
        [styles.compact]: compact,
      })}
      to={`${location.pathname}/${place_id}`}
    >
      {isEmpty(photos) ? (
        <div className={cx(styles.restaurantImage, "restaurantDefault")}>
          <img className={styles.defaultImage} src={defaultImage} alt="restaurant" />
        </div>
      ) : (
        <div className={styles.restaurantImage}>
          {photos.length <= 1 ? (
            <div className={styles.singleImage}>
              <DetailCarousel items={photos} />
            </div>
          ) : (
            <DetailCarousel items={photos} />
          )}
        </div>
      )}

      <div className={styles.restaurantInfo}>
        <p className={styles.restaurantName}>{name}</p>
        <RestaurantMainInfo
          rating={external_rating}
          user_ratings_total={external_ratings_total}
          price_level={price_level}
          distance={distance}
          visited={visited}
          visitedAt={visitedAt}
        />
        {/*
          <div className={styles.restaurantMetadata}>
            {opening_hours &&
              (opening_hours.open_now ? (
                <p className={cx(styles.statusTag, styles.open)}>Open</p>
              ) : (
                <p className={cx(styles.statusTag, styles.closed)}>Closed</p>
              ))}
          </div>
        */}
        <p className={styles.address}>{vicinity}</p>
        {visited ? <UserRating rating={rating} pushTop={pushTop} /> : null}
        {notes && (
          <p className={styles.notes}>
            <Icon className={styles.notesIcon} icon="annotation" />
            <span className={styles.notesText}>{notes}</span>
          </p>
        )}
      </div>

      {saved ? <span className={styles.tag}>Saved</span> : null}
    </Link>
  )
}

export default RestaurantItem
