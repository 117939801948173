import React from "react"
import { Tag, H6 } from "@blueprintjs/core"
import cx from "classnames"

import styles from "./toggeable-tags.module.scss"

const ToggleableTags = ({ className, tagSpace, value = [], onChange, title }) => {
  const toggleTag = tagValue => e => {
    if (value.includes(tagValue)) onChange(value.filter(v => v !== tagValue))
    else onChange(value.concat(tagValue))
  }
  return (
    <div className={cx(styles.container, className)}>
      <H6>{title}</H6>
      <div className={styles.tags}>
        {tagSpace.map(tag => (
          <Tag
            key={tag.value}
            className={styles.tag}
            large
            interactive
            active
            round
            minimal={!value.includes(tag.value)}
            onPointerDown={toggleTag(tag.value)}
          >
            {tag.label}
          </Tag>
        ))}
      </div>
    </div>
  )
}

export default ToggleableTags
