import { createSlice } from "@reduxjs/toolkit"
import store from "app/store"

const initialState = {
  lat: 0,
  long: 0,
  hasErrors: false,
  error: "",
  active: false,
}

const geolocationService = createSlice({
  name: "geolocationService",
  initialState,
  reducers: {
    setLocation(state, action) {
      state.lat = action.payload.lat
      state.long = action.payload.long
      state.hasErrors = false
      state.active = true
    },
    setError(state, action) {
      state.active = false
      state.hasErrors = true
      state.error = action.payload
    },
    disableLocation(state) {
      state.active = false
    },
  },
})

export default geolocationService

// -------------------------
// service initialization

function handleUpdatePosition(position) {
  console.log("...geo update", position)
  const pos = {
    lat: position.coords.latitude,
    long: position.coords.longitude,
  }
  localStorage.setItem("cache:geolocation", JSON.stringify(pos))

  store.dispatch(geolocationService.actions.setLocation(pos))
}

function handlePositionError(error) {
  console.log("Geoposition Error:", error)
  // try to return the last good know position
  const cachedValue = localStorage.getItem("cache:geolocation")
  if (cachedValue) {
    store.dispatch(geolocationService.actions.setLocation(JSON.parse(cachedValue)))
  } else {
    store.dispatch(geolocationService.actions.setError(error.message))
    // DEBUG AND DEVELOPMENT ONLY!!! TODO: DELETE!!!
    // store.dispatch(
    //   geolocationService.actions.setLocation({
    //     lat: 40.4058931,
    //     long: -3.6716154,
    //   }),
    // )
  }
}

if ("geolocation" in navigator) {
  // request a position right now
  navigator.geolocation.getCurrentPosition(handleUpdatePosition, handlePositionError, {
    enableHighAccuracy: true,
    maximumAge: 7500,
    timeout: 2500,
  })
  // periodic update
  navigator.geolocation.watchPosition(handleUpdatePosition, handlePositionError, { timeout: 30000 })
} else {
  store.dispatch(geolocationService.actions.disableLocation())
}

// -------------------------
// utils

export function distance(locationA, locationB) {
  return 0
}

// -------------------------
// selectors

export const getLocation = state => state.geolocationService

// -------------------------
// async actions
