import React from "react"
import { Marker } from "react-leaflet"
import { Icon } from "leaflet"

const CurrentPositionMarker = ({ location }) => {
  const locationIcon = new Icon({
    iconUrl: "/map/location.png",
    iconSize: [20, 20],
  })
  return <Marker icon={locationIcon} position={[location.lat, location.long]} />
}

export default CurrentPositionMarker
