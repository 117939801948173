import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getRestaurant as getRestaurantData,
  fetchRestaurant as fetchRestaurantData,
} from "./restaurantDetailsSlice"
import {
  getRestaurant,
  fetchRestaurant,
  saveRestaurant,
  updateRestaurant,
  visitRestaurant,
  deleteRestaurant,
} from "../restaurantsQueue/restaurantsQueueSlice"

import RestaurantDetailsPageView from "./RestaurantDetailsPageView"

function useFetchRestaurant(id, setLoading) {
  const dispatch = useDispatch()
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await Promise.all([dispatch(fetchRestaurantData(id)), dispatch(fetchRestaurant(id))])
      setLoading(false)
    }
    fetch()
    // eslint-disable-next-line
  }, [])
}

// -------------------------
// actions

function useDispatchWithLoading(setLoading, action) {
  const dispatch = useDispatch()
  return async (...args) => {
    setLoading(true)
    await dispatch(action(...args))
    setLoading(false)
  }
}

function useSaveRestaurant(restaurantData, setLoading) {
  return useDispatchWithLoading(setLoading, () => saveRestaurant(restaurantData))
}
function useVisitRestaurant(id, setLoading) {
  return useDispatchWithLoading(setLoading, restaurantPatch => visitRestaurant(id, restaurantPatch))
}
function useDeleteRestaurant(id, setLoading) {
  return useDispatchWithLoading(setLoading, () => deleteRestaurant(id))
}
function useUpdateRestaurant(id, setLoading) {
  return useDispatchWithLoading(setLoading, restaurantPatch =>
    updateRestaurant(id, restaurantPatch),
  )
}

const RestaurantDetailsPage = ({ backTo }) => {
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const { id } = useParams()
  const restaurantData = useSelector(getRestaurantData)
  const restaurant = useSelector(getRestaurant(id))
  // actions
  const handleSaveRestaurant = useSaveRestaurant(restaurantData, setSubmitting)
  const handleVisitRestaurant = useVisitRestaurant(id, setSubmitting)
  const handleDeleteRestaurant = useDeleteRestaurant(id, setSubmitting)
  const handleUpdateRestaurant = useUpdateRestaurant(id, setSubmitting)
  // form
  const initialValues = { ...restaurant, data: undefined }
  // fetch
  useFetchRestaurant(id, setLoading)
  return (
    <Formik initialValues={initialValues} onSubmit={handleUpdateRestaurant} enableReinitialize>
      {formikProps => (
        <RestaurantDetailsPageView
          backTo={backTo}
          onSave={handleSaveRestaurant}
          onVisit={handleVisitRestaurant}
          onDelete={handleDeleteRestaurant}
          onUpdate={handleUpdateRestaurant}
          restaurant={{ ...restaurant, data: restaurantData }}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          formik={formikProps}
        />
      )}
    </Formik>
  )
}

export default RestaurantDetailsPage
