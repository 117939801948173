import React from "react"
import { Classes, FormGroup, TextArea } from "@blueprintjs/core"
import cx from "classnames"
import RatingInput from "common/components/form/RatingInput"
import ToggleableTags from "common/components/form/ToggleableTags"
import { negativeTags, positiveTags } from "config/tags"
import styles from "./user-data-form.module.scss"

const UserDataForm = ({ formik, visited, isSubmitting }) => {
  return (
    <form onSubmit={formik.handleSubmit} className={styles.userForm}>
      <FormGroup
        label="Notes:"
        labelFor="notes"
        className={cx({ [Classes.SKELETON]: isSubmitting })}
      >
        <TextArea id="notes" onChange={formik.handleChange} value={formik.values.notes} />
      </FormGroup>

      {visited && (
        <FormGroup
          label="Rating:"
          labelFor="rating"
          className={cx({ [Classes.SKELETON]: isSubmitting })}
        >
          <RatingInput
            name="rating"
            selectedValue={String(formik.values.rating)}
            onChange={formik.handleChange}
          />
          <ToggleableTags
            title="Positive tags:"
            className={styles.positiveTags}
            tagSpace={positiveTags}
            onChange={positiveTagsValue => formik.setFieldValue("positiveTags", positiveTagsValue)}
            value={formik.values.positiveTags}
          />
          <ToggleableTags
            title="Negative tags:"
            className={styles.negativeTags}
            tagSpace={negativeTags}
            onChange={negativeTagsValue => formik.setFieldValue("negativeTags", negativeTagsValue)}
            value={formik.values.negativeTags}
          />
        </FormGroup>
      )}
    </form>
  )
}

export default UserDataForm
