import React from "react"
import AppLayout from "common/layouts/AppLayout"
import { Button, NonIdealState } from "@blueprintjs/core"
import Loader from "common/components/loaders/Loader"
import Restaurants from "common/components/restaurants/Restaurants"
import SearchBar from "common/components/bars/SearchBar"
import noLocation from "assets/images/no-location.png"

import styles from "./restaurants-search.module.scss"

const SearchPageForm = ({ isLoading, restaurants, formik }) => {
  return (
    <AppLayout
      className={styles.mainContainer}
      barSlot={<SearchBar isLoading={isLoading} formik={formik} />}
    >
      <div className={styles.container}>
        {isLoading ? (
          <Loader className="centered" />
        ) : (
          <Restaurants className={styles.searchResults} restaurants={restaurants} />
        )}
      </div>
    </AppLayout>
  )
}

const LocationUnavailable = () => (
  <AppLayout>
    <img className="messageImage" src={noLocation} alt="Location service unavailable" />
    <NonIdealState
      className={styles.container}
      title="Location Service Unavailable"
      description="We cannot show you the nearests restaurants without knowing where you are."
      action={<Button intent="primary">Enable Location</Button>}
    />
  </AppLayout>
)

const RestaurantsSearchPageView = props => {
  return props.isLocationActive ? <SearchPageForm {...props} /> : <LocationUnavailable />
}

export default RestaurantsSearchPageView
