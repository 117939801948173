import React from "react"
import { Icon } from "@blueprintjs/core"
import { Link } from "react-router-dom"
import cx from "classnames"

import styles from "./footer.module.scss"

const FooterLink = ({ to, text, icon, isActive }) => (
  <Link to={to} className={cx({ [styles.isActive]: isActive }, styles.footerLink)}>
    <Icon icon={icon} />
    <span className={styles.footerText}>{text}</span>
  </Link>
)

export default FooterLink
