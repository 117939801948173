import React from "react"
import { Intent } from "@blueprintjs/core"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Toaster from "features/toasterService/Toaster"
import PasswordRecoveryPageView from "./PasswordRecoveryPageView"

import { recoverPasswordAction } from "./userAuthSlice"

// -------------------------
// form logic

const initialValues = {
  email: "",
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(recoverPasswordAction(values.email))
      Toaster.show({
        icon: "envelope",
        intent: Intent.PRIMARY,
        message: "Email with reset code sent.",
        timeout: 1000,
      })
      history.push("/auth/password-reset")
    } catch (e) {
      Toaster.show({
        icon: "error",
        intent: Intent.DANGER,
        message: e.message,
        timeout: 1000,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordRecoveryPage = () => {
  const handleSubmit = useHandleSubmit()
  return <PasswordRecoveryPageView initialValues={initialValues} onSubmit={handleSubmit} />
}

export default PasswordRecoveryPage
