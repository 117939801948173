import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getRestaurants,
  getSearchQuery,
  searchRestaurants,
  fetchNearbyRestaurants,
} from "./restaurantsSearchSlice"
import { getLocation, distance } from "features/geolocationService/geolocationServiceSlice"
import { fetchQueue, getQueueRestaurants } from "features/restaurantsQueue/restaurantsQueueSlice"
import RestaurantsSearchPageView from "./RestaurantsSearchPageView"

// -------------------------
// config

const EMPTY_RESTAURANT = {
  id: "",
  notes: "",
  rating: 0,
  photos: [],
  saved: false,
  visited: false,
  data: null,
}

const DISTANCE_THRESHOLD = 10

// -------------------------
// api operations

function useSearchRestaurants(setLoading) {
  const dispatch = useDispatch()
  const location = useSelector(getLocation)
  return async values => {
    setLoading(true)
    const { lat, long } = location
    if (values.query !== "") {
      await dispatch(searchRestaurants(values.query, lat, long))
    } else {
      await dispatch(fetchNearbyRestaurants(lat, long))
    }
    setLoading(false)
  }
}

// -------------------------
// component

const RestaurantsSearchPage = () => {
  const [isLoading, setLoading] = useState(true)
  const [lastLocation, setLastLocation] = useState(null)
  const searchQuery = useSelector(getSearchQuery)
  const restaurantsData = useSelector(getRestaurants)
  const location = useSelector(getLocation)
  // form
  const searchRestaurants = useSearchRestaurants(setLoading)
  const initialValues = { query: searchQuery.query }
  // queue
  const queueRestaurants = useSelector(getQueueRestaurants)
  const restaurants = restaurantsData.map(rd => ({
    ...EMPTY_RESTAURANT,
    ...queueRestaurants[rd.place_id],
    data: rd,
    id: rd.place_id,
  }))
  // first request
  const dispatch = useDispatch()
  useEffect(() => {
    if (location.active) {
      if (lastLocation === null || distance(lastLocation, location) > DISTANCE_THRESHOLD) {
        setLastLocation(location)
        dispatch(fetchQueue())
        searchRestaurants(searchQuery)
      }
    }
    // eslint-disable-next-line
  }, [location, lastLocation])

  // useFetchNearbyRestaurants(location.lat, location.long, RADIUS, setLoading)
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={searchRestaurants}>
      {formikProps => (
        <RestaurantsSearchPageView
          isLocationActive={!location.hasErrors}
          isLoading={isLoading || !location.active}
          restaurants={restaurants}
          formik={formikProps}
        />
      )}
    </Formik>
  )
}

export default RestaurantsSearchPage
