import React from "react"
import FooterLink from "./FooterLink"
import { useRouteMatch } from "react-router-dom"

import { ReactComponent as Check } from "assets/icons/icon-check.svg"
import { ReactComponent as Pin } from "assets/icons/icon-pin.svg"
import { ReactComponent as Users } from "assets/icons/icon-users.svg"
import { ReactComponent as Search } from "assets/icons/icon-search.svg"
import { ReactComponent as List } from "assets/icons/icon-list.svg"

import styles from "./footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles.mainFooter}>
      <FooterLink
        to="/search"
        icon={<Search />}
        text="Search"
        isActive={useRouteMatch("/search")}
      />
      <FooterLink
        to="/queue"
        icon={<List className={styles.iconList} />}
        text="Queue"
        isActive={useRouteMatch("/queue")}
      />
      <FooterLink
        to="/visited"
        icon={<Check />}
        text="Visited"
        isActive={useRouteMatch("/visited")}
      />
      <FooterLink to="/map" icon={<Pin />} text="Map" isActive={useRouteMatch("/map")} />
      <FooterLink to="/users" icon={<Users />} text="Social" isActive={useRouteMatch("/users")} />
    </footer>
  )
}

export default Footer
