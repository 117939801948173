import React, { useState } from "react"
import AppLayout from "common/layouts/AppLayout"
import Tabs from "common/components/tabs/Tabs"
import BackLink from "common/components/links/BackLink"
import Restaurants from "common/components/restaurants/Restaurants"

import { MapContainer, TileLayer } from "react-leaflet"
import CurrentPositionMarker from "features/restaurantsMap/CurrentPositionMarker"
import queueIcon from "features/restaurantsMap/queueIcon"
import likedIcon from "features/restaurantsMap/likedIcon"
import dislikedIcon from "features/restaurantsMap/dislikedIcon"
import RestaurantMarkers from "features/restaurantsMap/RestaurantMarkers"
import mapStyles from "features/restaurantsMap/restaurants-map.module.scss"

import styles from "./social-detail.module.scss"

const SocialUserDetailsPageView = ({ isLoading, queue, visited, isLocationActive, location }) => {
  const [tab, setTab] = useState("queue")

  const liked = visited.filter(r => r.rating > 0)
  const disliked = visited.filter(r => r.rating < 0)
  const latLong = isLocationActive ? [location.lat, location.long] : [40.41831, -3.70275]

  // tabs
  const tabQueue = {
    onClick: () => setTab("queue"),
    active: tab === "queue",
    text: "Queue",
  }
  const tabVisited = {
    onClick: () => setTab("visited"),
    active: tab === "visited",
    text: "Visited",
  }
  const tabMap = {
    onClick: () => setTab("map"),
    active: tab === "map",
    text: "Map",
  }

  // tab contents
  const tabContents = {
    queue: <Restaurants restaurants={queue} />,
    visited: <Restaurants restaurants={visited} />,
    map: (
      <MapContainer
        className={mapStyles.mapContainer}
        center={latLong}
        zoom={17}
        minZoom={13}
        maxZoom={18}
        zoomControl={false}
        attributionControl={false}
        inertia={true}
        inertiaDeceleration={1500}
        touchZoom={true}
      >
        <TileLayer
          id="mapbox/streets-v11"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          /* url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png" */
        />
        {isLocationActive && <CurrentPositionMarker location={location} />}
        <RestaurantMarkers icon={queueIcon} restaurants={queue} />
        <RestaurantMarkers icon={likedIcon} restaurants={liked} />
        <RestaurantMarkers icon={dislikedIcon} restaurants={disliked} />
      </MapContainer>
    ),
  }

  // TODO: que pasa cuando no hay queue o visitados
  // TODO: non-ideal state cuando el usuario no existe
  // TODO: que pasa cuando no hay localizacion (mostrar non-ideal state en lugar del mapa)
  // TODO: mover los componentes del mapa a /common
  // TODO: restaurar pesta~a y posicion en el mapa al VOLVER de restaurant details

  return (
    <AppLayout className={styles.socialDetail}>
      <div className={styles.topBar}>
        <BackLink to="/users" />
        <p className={styles.userName}>Username List</p>
      </div>
      <Tabs className={styles.tabs} tab1={tabQueue} tab2={tabVisited} tab3={tabMap}></Tabs>
      {tabContents[tab]}
    </AppLayout>
  )
}

export default SocialUserDetailsPageView
