import React from "react"
import { useParams } from "react-router-dom"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import RegisterPage from "features/userAuth/RegisterPage"
import EmailConfirmationPage from "features/userAuth/EmailConfirmationPage"
import LoginPage from "features/userAuth/LoginPage"
import LogoutPage from "features/userAuth/LogoutPage"
import PasswordRecoveryPage from "features/userAuth/PasswordRecoveryPage"
import PasswordResetPage from "features/userAuth/PasswordResetPage"
import RestaurantsSearchPage from "features/restaurantsSearch/RestaurantsSearchPage"
import RestaurantsQueuePage from "features/restaurantsQueue/RestaurantsQueuePage"
import RestaurantsVisitedPage from "features/restaurantsQueue/RestaurantsVisitedPage"
import RestaurantDetailsPage from "features/restaurantDetails/RestaurantDetailsPage"
import RestaurantsMapPage from "features/restaurantsMap/RestaurantsMapPage"
import UserSettingsPage from "features/userSettings/UserSettingsPage"
import SocialUsersListPage from "features/socialUsersList/SocialUsersListPage"
import SocialUserDetailsPage from "features/socialUserDetails/SocialUserDetailsPage"
import NotFoundPage from "common/pages/NotFoundPage"
import HomePage from "features/homePage/HomePage"

// import PageIndex from "dev-tools/PageIndex"

import "./app.module.scss"

const SocialRestaurantDetailsAux = (...props) => {
  const { userid } = useParams()
  return <RestaurantDetailsPage {...props} backTo={`/users/${userid}`} />
}

const App = () => (
  <Router>
    <Switch>
      {/* auth */}
      <Route path="/auth/register" component={RegisterPage} />
      <Route path="/auth/email-confirmation" component={EmailConfirmationPage} />
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/password-recovery" component={PasswordRecoveryPage} />
      <Route path="/auth/password-reset" component={PasswordResetPage} />
      <Route path="/auth/logout" component={LogoutPage} />

      {/* queue */}
      <Route path="/queue" exact component={RestaurantsQueuePage} />
      <Route
        path="/queue/:id"
        render={(...routerProps) => <RestaurantDetailsPage {...routerProps} backTo="/queue" />}
      />

      {/* visited */}
      <Route path="/visited" exact component={RestaurantsVisitedPage} />
      <Route
        path="/visited/:id"
        render={(...routerProps) => <RestaurantDetailsPage {...routerProps} backTo="/visited" />}
      />

      {/* restaurants */}
      <Route path="/search" exact component={RestaurantsSearchPage} />
      <Route
        path="/search/:id"
        render={(...routerProps) => <RestaurantDetailsPage {...routerProps} backTo="/search" />}
      />

      {/* map */}
      <Route path="/map" exact component={RestaurantsMapPage} />
      <Route
        path="/map/:id"
        render={(...routerProps) => <RestaurantDetailsPage {...routerProps} backTo="/map" />}
      />

      {/* social */}
      <Route path="/users" exact component={SocialUsersListPage} />
      <Route path="/users/:userid/:id" component={SocialRestaurantDetailsAux} />
      <Route
        path="/users/:id"
        render={(...routerProps) => <SocialUserDetailsPage {...routerProps} />}
      />

      {/* settings */}
      <Route path="/settings" component={UserSettingsPage} />

      <Route path="/home" component={HomePage} />

      {/* <Route exact path="/" component={PageIndex} /> */}
      <Route exact path="/">
        <Redirect to="/search" />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
)

export default App
