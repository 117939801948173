import React from "react"
import AppLayout from "common/layouts/AppLayout"
import { H2, FormGroup, InputGroup, Button, Classes, Intent } from "@blueprintjs/core"
import { Formik } from "formik"
import validate from "validate.js"
import Loader from "common/components/loaders/Loader"
import restaurantSettings from "assets/images/settings.png"

import styles from "./user-settings.module.scss"
import UserMenu from "common/components/user/UserMenu"

// -------------------------
// form validation

const constraints = {
  postalCode: { format: /^\d+$/ },
}

function validateForm(values) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const UserSettingsPageView = ({ onSubmit, initialValues, isLoading }) => {
  return (
    <AppLayout>
      <div className={styles.header}>
        <UserMenu />
      </div>
      <div className={styles.settings}>
        <img className="messageImage" alt="restauratnt" src={restaurantSettings} />
        <H2 className={styles.title}>Account Settings</H2>
        <Formik
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
            <form>
              <div className={styles.settingsForm}>
                <div className={styles.settingsCol}>
                  <FormGroup
                    label="Email"
                    labelFor="email"
                    className={(isLoading || isSubmitting) && Classes.SKELETON}
                  >
                    <InputGroup id="email" disabled value={values.email} large />
                  </FormGroup>
                  <FormGroup
                    label="Name"
                    labelFor="name"
                    className={(isLoading || isSubmitting) && Classes.SKELETON}
                    intent={touched.name && errors.name && Intent.DANGER}
                    helperText={touched.name && errors.name}
                  >
                    <InputGroup
                      large
                      id="name"
                      onChange={handleChange}
                      intent={touched.name && errors.name && Intent.DANGER}
                      value={values.name}
                    />
                  </FormGroup>
                  <div className={styles.settingsActions}>
                    {isLoading || isSubmitting ? (
                      <Loader />
                    ) : (
                      <Button icon="floppy-disk" intent="primary" onClick={handleSubmit} large>
                        Save Account Settings
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </AppLayout>
  )
}

export default UserSettingsPageView
