import React from "react"
import PropTypes from "prop-types"
import ProtectedContent from "common/meta/ProtectedContent"
import Footer from "common/components/footer/Footer"
import cx from "classnames"

import styles from "./app-layout.module.scss"

const AppLayout = ({ children, className, barSlot, footer, noTopSpace }) => {
  return (
    <ProtectedContent>
      <div className={cx(styles.outerContainer, { noFooter: !footer })}>
        {barSlot && <div className={styles.barSlot}>{barSlot}</div>}
        <main className={cx(className, styles.mainContainer, { [styles.noTopSpace]: noTopSpace })}>
          {children}
        </main>
        {footer && <Footer />}
      </div>
    </ProtectedContent>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  barslot: PropTypes.node,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  noTopSpace: PropTypes.bool,
}

AppLayout.defaultProps = {
  header: true,
  footer: true,
  noTopSpace: false,
}

export default AppLayout
