import { Icon } from "@blueprintjs/core"
import React from "react"
import { Link } from "react-router-dom"
import styles from "./back-link.module.scss"

const BackLink = ({ to }) => {
  return (
    <Link to={to} className={styles.backLink}>
      <Icon icon="arrow-left" />
    </Link>
  )
}

export default BackLink
