import { createSlice } from "@reduxjs/toolkit"
import Cognito from "api/cognito"
import { registerUser } from "api/social"

const initialState = {
  currentUser: null,
}

const userAuth = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setUserSession(state, action) {
      state.currentUser = action.payload
      state.error = null
    },
    unsetUserSession(state) {
      state.currentUser = null
    },
  },
})

export default userAuth

// -------------------------
// selectors

export const getCurrentUser = state => state.userAuth.currentUser

// -------------------------
// async actions

export const fetchAuthenticatedUser = () => async dispatch => {
  const user = await Cognito.currentAuthenticatedUser()
  window["user"] = user
  dispatch(userAuth.actions.setUserSession(user.attributes))
}

export const registerUserAction = (email, password, username) => async () => {
  await Cognito.signUp(email, password, username)
}

export const loginUserAction = (email, password) => async dispatch => {
  const user = await Cognito.signIn(email, password)
  await registerUser(user.attributes)
  dispatch(userAuth.actions.setUserSession(user.attributes))
}

export const recoverPasswordAction = email => async () => {
  await Cognito.forgotPassword(email)
}

export const resetPasswordAction = (email, code, password) => async () => {
  const resp = await Cognito.recoveryChangePassword(email, code, password)
  console.log(resp)
}

export const logoutUserAction = () => async dispatch => {
  await Cognito.signOut()
  dispatch(userAuth.actions.unsetUserSession)
}
