import React from "react"
import AppLayout from "common/layouts/AppLayout"
import restaurants from "../../assets/images/restaurants.png"
import logo from "../../assets/images/logo.png"
import { Link } from "react-router-dom"

import styles from "./home-page.module.scss"

const HomePageView = () => {
  return (
    <AppLayout footer={false}>
      <div className={styles.home}>
        <img src={logo} alt="Bar Queue" className={styles.logo} />
        <img src={restaurants} alt="restaurants" className={styles.image} />
        <p className={styles.description}>
          Create a queue with all the restaurants you want to visit. <br />
          See your friends favourite places.
        </p>
        <div className={styles.actions}>
          <Link className={styles.button} to="/auth/login">
            Login
          </Link>
          <Link className={styles.button} to="/auth/register">
            Register
          </Link>
        </div>
      </div>
    </AppLayout>
  )
}

export default HomePageView
