import React from "react"
import ReactDOM from "react-dom"
import AuthLayout from "common/layouts/AuthLayout"
import { Intent, Classes, FormGroup, InputGroup, Button } from "@blueprintjs/core"
import { Formik } from "formik"
import validate from "validate.js"
import Loader from "common/components/loaders/Loader"

import styles from "../../common/layouts/auth-layout.module.scss"

// -------------------------
// form validation

const constraints = {
  email: { presence: { allowEmpty: false }, email: true },
  code: { format: /^\d{6}$/ },
  password: { presence: true, length: { minimum: 6, maximum: 10 } },
  "confirm-password": { equality: "password" },
}

function validateForm(values) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const PasswordResetPageView = ({ initialValues, onSubmit }) => (
  <AuthLayout title="Reset password" subtitle="Use the code you received by email.">
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={styles.form}>
          <FormGroup
            label="Email Address"
            labelInfo="*"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.email && errors.email && Intent.DANGER}
            helperText={touched.email && errors.email && errors.email[0]}
          >
            <InputGroup
              large
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={touched.email && errors.email && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="Verification Code"
            labelInfo="*"
            labelFor="code"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.code && errors.code && Intent.DANGER}
            helperText={touched.code && errors.code}
          >
            <InputGroup
              large
              id="code"
              placeholder="000000"
              type="text"
              value={values.code}
              onChange={handleChange}
              intent={touched.code && errors.code && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="New Password"
            labelInfo="*"
            labelFor="password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.password && errors.password && Intent.DANGER}
            helperText={touched.password && errors.password}
          >
            <InputGroup
              large
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              intent={touched.password && errors.password && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="Confirm Password"
            labelInfo="*"
            labelFor="confirm-password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched["confirm-password"] && errors["confirm-password"] && Intent.DANGER}
            helperText={touched["confirm-password"] && errors["confirm-password"]}
          >
            <InputGroup
              large
              id="confirm-password"
              type="password"
              value={values["confirm-password"]}
              onChange={handleChange}
              intent={touched["confirm-password"] && errors["confirm-password"] && Intent.DANGER}
            />
          </FormGroup>
          {isSubmitting ? (
            <Loader />
          ) : (
            ReactDOM.createPortal(
              <div className={styles.formActions}>
                <Button fill intent="primary" large onClick={handleSubmit}>
                  Reset password
                </Button>
              </div>,
              document.getElementById("action-bar"),
            )
          )}
        </form>
      )}
    </Formik>
  </AuthLayout>
)

export default PasswordResetPageView
