import { createSlice } from "@reduxjs/toolkit"
import * as api from "api/restaurants/search"
import { randToken } from "common/utils/general"
import { NEARBY_RESTAURANT_RADIUS as RADIUS } from "config/constants"

const initialState = {
  restaurants: [],
  searchQuery: { query: "", lat: 0, long: 0 },
}

const restaurantsSearch = createSlice({
  name: "restaurantsSearch",
  initialState,
  reducers: {
    setRestaurantList(state, action) {
      state.restaurants = action.payload
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload
    },
  },
})

export default restaurantsSearch

// -------------------------
// selectors

export const getRestaurants = state => state.restaurantsSearch.restaurants
export const getSearchQuery = state => state.restaurantsSearch.searchQuery

// -------------------------
// async actions

let lastRequestToken = null

export const searchRestaurants = (query, lat, long) => async dispatch => {
  const token = randToken()
  lastRequestToken = token
  const restaurants = await api.searchRestaurants(query, lat, long)
  if (lastRequestToken === token) {
    const results = restaurants.slice(0, 5)
    dispatch(restaurantsSearch.actions.setSearchQuery({ query, lat, long }))
    dispatch(restaurantsSearch.actions.setRestaurantList(results))
  }
}

export const fetchNearbyRestaurants = (lat, long) => async dispatch => {
  const token = randToken()
  lastRequestToken = token
  const restaurants = await api.nearbyRestaurants(lat, long, RADIUS)
  if (lastRequestToken === token) {
    // const results = restaurants.slice(0, 5)
    const results = restaurants
    dispatch(restaurantsSearch.actions.setSearchQuery(initialState.searchQuery))
    dispatch(restaurantsSearch.actions.setRestaurantList(results))
  }
}
