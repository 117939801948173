import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocation } from "features/geolocationService/geolocationServiceSlice"
import {
  fetchQueue,
  getNextRestaurantsByDate,
  getNextRestaurantsByDistance,
} from "./restaurantsQueueSlice"

import RestaurantsQueuePageView from "./RestaurantsQueuePageView"

function useFetchQueue() {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await dispatch(fetchQueue())
      setLoading(false)
    }
    fetch()
    // eslint-disable-next-line
  }, [])
  return isLoading
}

const RestaurantsQueuePage = () => {
  const location = useSelector(getLocation)
  const queueByDistance = useSelector(getNextRestaurantsByDistance)
  const queueByDate = useSelector(getNextRestaurantsByDate)
  const isLoading = useFetchQueue()
  return (
    <RestaurantsQueuePageView
      restaurantsByDistance={queueByDistance}
      restaurantsByDate={queueByDate}
      isLocationActive={!location.hasErrors}
      isLoading={isLoading || !location.active}
    />
  )
}

export default RestaurantsQueuePage
