import React from "react"
import AppLayout from "common/layouts/AppLayout"
import SocialUsersList from "common/components/social/SocialUserList"
import TopBar from "common/components/bars/TopBar"

const SocialUsersListPageView = ({ isLoading, users }) => {
  return (
    <AppLayout noTopSpace barSlot={<TopBar title="Bar Queue Users" />}>
      <SocialUsersList users={users} />
    </AppLayout>
  )
}

export default SocialUsersListPageView
