import React from "react"
import AppLayout from "common/layouts/AppLayout"
import RestaurantList from "common/components/queue/RestaurantList"

const RestaurantsQueuePageView = props => {
  return (
    <AppLayout>
      <RestaurantList {...props} />
    </AppLayout>
  )
}

export default RestaurantsQueuePageView
