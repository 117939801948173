import { Auth } from "aws-amplify"
import awsExports from "config/aws-exports"

Auth.configure(awsExports.auth)

export function respondToAuthChallenge(cognitoUser, newPassword) {
  return Auth.completeNewPassword(cognitoUser, newPassword, null)
}

export function signUp(email, password, username) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email: email,
      name: username,
    },
  })
}

export function signIn(email, password) {
  return Auth.signIn(email, password)
}

export function signOut() {
  return Auth.signOut()
}

export function forgotPassword(email) {
  return Auth.forgotPassword(email)
}

export function recoveryChangePassword(email, code, newPassword) {
  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

export function currentSession() {
  return Auth.currentSession()
}

export function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
}

export async function currentUserToken() {
  const user = await currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}

export async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser()
  return Auth.changePassword(user, oldPassword, newPassword)
}

export async function updateUserAttributes(attributes) {
  const user = await Auth.currentAuthenticatedUser()
  return Auth.updateUserAttributes(user, attributes)
}

export default {
  signUp,
  signIn,
  signOut,
  forgotPassword,
  recoveryChangePassword,
  currentSession,
  currentAuthenticatedUser,
  changePassword,
}

export { CognitoUserSession } from "amazon-cognito-identity-js"
