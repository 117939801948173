import React from "react"
import { Dialog } from "@blueprintjs/core"

import styles from "./modal.module.scss"

const Modal = ({ onClose, iconModal, title, isOpen, usePortal, children }) => {
  return (
    <Dialog
      className={styles.modal}
      onClose={onClose}
      icon={iconModal}
      title={title}
      isOpen={isOpen}
      usePortal={usePortal}
    >
      {children}
    </Dialog>
  )
}

export default Modal
