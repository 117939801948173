import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Toaster from "features/toasterService/Toaster"
import UserSettingsPageView from "./UserSettingsPageView"
import {
  // fetchSettings,
  fetchCognitoAttributes,
  getUserSettings,
  // saveSettings,
  saveCognitoAttributes,
} from "./userSettingsSlice"

// -------------------------
// connection logic: queries

function useFetchSettings() {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      // await Promise.all([dispatch(fetchSettings()), dispatch(fetchCognitoAttributes())]);
      await Promise.all([dispatch(fetchCognitoAttributes())])
      setLoading(false)
    }
    fetch()
  }, [dispatch, setLoading])
  return isLoading
}

function useInitialvalues() {
  const userSettings = useSelector(getUserSettings)
  return { ...userSettings.cognitoAttributes }
}

// -------------------------
// connection logic: commands

function useHandleSubmit() {
  const dispatch = useDispatch()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    const attributes = values
    // const settings: Settings = { billingAddress: values as BillingAddress };
    try {
      await Promise.all([
        dispatch(saveCognitoAttributes(attributes)),
        // dispatch(saveSettings(settings)),
      ])
      Toaster.show({
        icon: "floppy-disk",
        intent: "success",
        message: "settings saved successfully",
        timeout: 1000,
      })
    } catch (e) {
      Toaster.show({
        icon: "error",
        intent: "danger",
        message: e.message,
        timeout: 1000,
      })
    }
    setSubmitting(false)
  }
}

// -------------------------
// ui component

const UserSettingsPage = () => {
  const handleSubmit = useHandleSubmit()
  const isLoading = useFetchSettings()
  const initialValues = useInitialvalues()
  return (
    <UserSettingsPageView
      isLoading={isLoading}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  )
}

export default UserSettingsPage
