import React from "react"
import { Button, ButtonGroup } from "@blueprintjs/core"
import styles from "./tabs.module.scss"
import UserMenu from "../user/UserMenu"
import cx from "classnames"

const Tabs = ({ tab1, tab2, tab3, className, children }) => {
  return (
    <div className={cx(styles.tabsWrapper, className)}>
      <ButtonGroup minimal fill className={styles.tabs}>
        {children}
        <Button onClick={tab1.onClick} active={tab1.active}>
          {tab1.text}
        </Button>
        <Button onClick={tab2.onClick} active={tab2.active}>
          {tab2.text}
        </Button>
        {tab3 && (
          <Button onClick={tab3.onClick} active={tab3.active}>
            {tab3.text}
          </Button>
        )}
      </ButtonGroup>
      <UserMenu blue />
    </div>
  )
}

export default Tabs
