import { createSlice } from "@reduxjs/toolkit"
import { getDistance } from "geolib"
import * as api from "api/restaurants/restaurant"

const initialState = {
  restaurant: {
    place_id: "",
    name: "",
    formatted_address: "",
    geometry: { location: { lat: 0, lng: 0 } },
    reference: "",
    types: [],
  },
}

const restaurantDetails = createSlice({
  name: "restaurantDetails",
  initialState,
  reducers: {
    setRestaurantDetails(state, action) {
      state.restaurant = action.payload
    },
  },
})

export default restaurantDetails

// -------------------------
// selectors

export const getRestaurant = state => {
  const restaurant = {
    ...state.restaurantDetails.restaurant,
    distance: false,
  }
  const location = state.geolocationService
  if (location.active && !location.hasErrors) {
    restaurant.distance = getDistance(
      {
        latitude: location.lat,
        longitude: location.long,
      },
      {
        latitude: restaurant.geometry.location.lat,
        longitude: restaurant.geometry.location.lng,
      },
    )
  }
  return restaurant
}

// -------------------------
// async actions

export const fetchRestaurant = id => async dispatch => {
  dispatch(restaurantDetails.actions.setRestaurantDetails(initialState.restaurant))
  const restaurant = await api.getRestaurant(id)
  dispatch(restaurantDetails.actions.setRestaurantDetails(restaurant))
}

// export const createRestaurant = (props: Restaurant): AppThunk => async dispatch => {
//     const restaurant: Restaurant = await api.createRestaurant(props);
//     dispatch(restaurantDetails.actions.setRestaurantDetails(restaurant));
// };

// export const updateRestaurant = (id: string, props: Restaurant): AppThunk => async dispatch => {
//     const restaurant: Restaurant = await api.updateRestaurant(id, props);
//     dispatch(restaurantDetails.actions.setRestaurantDetails(restaurant));
// };
