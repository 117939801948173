import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocation } from "features/geolocationService/geolocationServiceSlice"
import {
  fetchQueue,
  getNextRestaurantsByDistance,
  getVisitedRestaurantsByDistance,
} from "features/restaurantsQueue/restaurantsQueueSlice"
import RestaurantsMapPageView from "./RestaurantsMapPageView"

function useFetchQueue() {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    let aborted = false
    const fetch = async () => {
      setLoading(true)
      await dispatch(fetchQueue())
      if (!aborted) setLoading(false)
    }
    fetch()
    return () => {
      aborted = true
    }
  }, [])
  return isLoading
}
const RestaurantsMapPage = () => {
  const location = useSelector(getLocation)
  const queueByDistance = useSelector(getNextRestaurantsByDistance)
  const visitedByDistance = useSelector(getVisitedRestaurantsByDistance)
  const likedByDistance = visitedByDistance.filter(r => r.rating > 0)
  const dislikedByDistance = visitedByDistance.filter(r => r.rating < 0)
  const isLoading = useFetchQueue()
  return (
    <RestaurantsMapPageView
      queueByDistance={queueByDistance}
      likedByDistance={likedByDistance}
      dislikedByDistance={dislikedByDistance}
      isLocationActive={location.active && !location.hasErrors}
      location={location}
      isLoading={isLoading || !location.active}
    />
  )
}

export default RestaurantsMapPage
