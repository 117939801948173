import React from "react"
import { H2 } from "@blueprintjs/core"
import styles from "./auth-layout.module.scss"
import { Link } from "react-router-dom"
import logo from "assets/images/logo.svg"
import restaurants from "assets/images/restaurants.svg"
import useRevealEffect from "../../hooks/effects/useRevealEffect"

const AuthLayout = ({ children, title, subtitle }) => {
  const sectionRef = useRevealEffect({ y: 30 })

  return (
    <div className={styles.outerContainer} ref={sectionRef}>
      <div className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="Bar Queue" />
        </Link>
        <img src={restaurants} alt="restaurants" className={styles.headerImage} />
      </div>

      <main className={styles.mainContainer}>
        <H2 className={styles.title}>{title}</H2>
        <p className={styles.subtitle}>{subtitle}</p>
        {children}
      </main>
    </div>
  )
}
export default AuthLayout
