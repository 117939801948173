import { createSlice } from "@reduxjs/toolkit"
import * as api from "api/social"
import { currentAuthenticatedUser } from "api/cognito"

const initialState = {
  users: [],
}

const socialUsersList = createSlice({
  name: "socialUsersList",
  initialState,
  reducers: {
    setUsersList(state, action) {
      state.users = action.payload
    },
  },
})

export default socialUsersList

// -------------------------
// selectors

export const getUsers = state => state.socialUsersList.users

// -------------------------
// async actions

export const fetchUsersList = () => async dispatch => {
  const currentUser = await currentAuthenticatedUser()
  const sub = currentUser.attributes.sub
  const users = await api.fetchUsersList()
  const withoutCurrentUer = users.filter(u => u.user !== sub)
  dispatch(socialUsersList.actions.setUsersList(withoutCurrentUer))
  // dispatch(socialUsersList.actions.setUsersList(users))
}
