import { Button } from "@blueprintjs/core"
import RestaurantDetail from "common/components/restaurants/RestaurantDetail"
import AppLayout from "common/layouts/AppLayout"
import React, { useCallback, useState } from "react"
import ActionBar from "../../common/components/bars/ActionBar"
import BackLink from "../../common/components/links/BackLink"
import DeleteRestaurantModal from "./DeleteRestaurantModal"
import VisitRestaurantModal from "./VisitRestaurantModal"
import Loader from "common/components/loaders/Loader"
import { ReactComponent as Trash } from "assets/icons/icon-trash.svg"

const RestaurantDetailsPageView = ({
  isLoading,
  isSubmitting,
  restaurant,
  onSave,
  onVisit,
  onDelete,
  onUpdate,
  formik,
  backTo,
}) => {
  const { saved, visited } = restaurant

  // visit modal logic
  const [isVisitModalOpen, setVisitModalOpen] = useState(false)
  const closeVisitModal = useCallback(() => setVisitModalOpen(false), [setVisitModalOpen])
  const openVisitModal = useCallback(() => setVisitModalOpen(true), [setVisitModalOpen])
  const handleVisitRestaurant = useCallback(
    restaurantPatch => {
      onVisit(restaurantPatch)
      setVisitModalOpen(false)
    },
    [onVisit, setVisitModalOpen],
  )

  // delete modal logic
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), [setDeleteModalOpen])
  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), [setDeleteModalOpen])
  const handleDeleteRestaurant = useCallback(() => {
    onDelete()
    setDeleteModalOpen(false)
  }, [onDelete, setDeleteModalOpen])

  if (isLoading) {
    return <Loader className="centered" />
  }

  return (
    <AppLayout
      header={false}
      footer={false}
      barSlot={
        <ActionBar leftActions={<BackLink to={backTo} />}>
          {/* the restaurant is NOT saved -> show [save] button */}
          {!saved && (
            <Button loading={isSubmitting} onClick={onSave} intent="primary" icon="floppy-disk">
              Save
            </Button>
          )}

          {/* the restaurant is SAVED (visited or not) -> show [delete] button */}
          {saved && (
            <Button
              loading={isSubmitting}
              onClick={openDeleteModal}
              intent="danger"
              icon={<Trash className="icon" />}
            >
              Delete
            </Button>
          )}

          {/* the restaurant is SAVED but not visited -> show [visit] button */}
          {saved && !visited && (
            <Button loading={isSubmitting} onClick={openVisitModal} intent="primary" icon="confirm">
              Visit
            </Button>
          )}

          {/* modales */}

          <VisitRestaurantModal
            isOpen={isVisitModalOpen}
            openModal={openVisitModal}
            closeModal={closeVisitModal}
            restaurant={restaurant}
            onVisit={handleVisitRestaurant}
          />

          <DeleteRestaurantModal
            isOpen={isDeleteModalOpen}
            openModal={openDeleteModal}
            closeModal={closeDeleteModal}
            restaurant={restaurant}
            onDelete={handleDeleteRestaurant}
          />
        </ActionBar>
      }
    >
      <RestaurantDetail restaurant={restaurant} formik={formik} isSubmitting={isSubmitting} />
    </AppLayout>
  )
}

export default RestaurantDetailsPageView
