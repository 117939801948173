import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import styles from "./social-user-list.module.scss"

const SocialUsersList = ({ isLoading, users }) => {
  return (
    <ul className={styles.userList}>
      {users.map(user => {
        return (
          <li className={styles.userListItem} key={user.id}>
            <Link to={`/users/${user.user}`} className={styles.userLink}>
              <div className={styles.userAvatarWrapper}>
                <img className={styles.userAvatar} src={user.avatar} alt={user.name} />
              </div>
              <div className={styles.userInfo}>
                <p className={styles.userName}>{user.name}</p>
                <p className={styles.userEmail}>{user.email}</p>
                <div className={styles.userExtra}>
                  <div className={cx(styles.extraItem, [styles.pending])}>
                    <span className={styles.label}>Pending: </span>
                    <span className={styles.value}> {user.stats.pending}</span>
                  </div>
                  <div className={cx(styles.extraItem, [styles.liked])}>
                    <span className={styles.label}>Liked: </span>
                    <span className={styles.value}> {user.stats.liked}</span>
                  </div>
                  <div className={cx(styles.extraItem, [styles.disliked])}>
                    <span className={styles.label}>Disliked: </span>
                    <span className={styles.value}> {user.stats.disliked}</span>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default SocialUsersList
