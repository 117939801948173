import React from "react"
import { InputGroup, Button } from "@blueprintjs/core"
import cx from "classnames"
import styles from "./search-bar.module.scss"
import UserMenu from "common/components/user/UserMenu"

const SearchBar = ({ isLoading, formik }) => {
  return (
    <div className={styles.searchBar}>
      <form onSubmit={formik.handleSubmit}>
        <InputGroup
          className={cx(styles.searchInput, { [styles.isLoading]: isLoading })}
          id="query"
          leftIcon="search"
          onChange={formik.handleChange}
          placeholder="Search nearby restaurants"
          value={formik.values.query}
          large={true}
          rightElement={
            <Button
              icon="arrow-right"
              onClick={formik.handleSubmit}
              loading={isLoading}
              minimal={true}
            />
          }
        />
      </form>
      <UserMenu />
    </div>
  )
}

export default SearchBar
