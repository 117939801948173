import axios from "axios"
import { currentUserToken } from "api/cognito"
import { withCache, MINUTES } from "common/utils/cache"
import { API_URL } from "config/settings"

export const getRestaurant = withCache(10 * MINUTES, [0], async id => {
  const userToken = await currentUserToken()
  const response = await axios.get(`/restaurants/${id}`, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
})

// export async function createRestaurant(values: Restaurant) {
//     const userToken = await currentUserToken();
//     const response = await axios.post("/restaurants", values, {
//         baseURL: API_URL,
//         headers: { Authorization: userToken },
//     });
//     return response.data;
// }

// export async function updateRestaurant(id: string, values: Restaurant) {
//     const userToken = await currentUserToken();
//     const response = await axios.put(`/restaurants/${id}`, values, {
//         baseURL: API_URL,
//         headers: { Authorization: userToken },
//     });
//     return response.data;
// }
