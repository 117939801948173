import { Button, Classes, H4 } from "@blueprintjs/core"
import UserDataForm from "common/components/restaurants/UserDataForm"
import Modal from "common/components/modals/Modal"
import { Formik } from "formik"
import React from "react"
import { ReactComponent as Check } from "assets/icons/icon-check.svg"
import RestaurantImage from "assets/images/visit-restaurant.png"

const VisitRestaurantModal = ({ isOpen, closeModal, onVisit, restaurant }) => {
  const initialValues = { ...restaurant, data: undefined }
  return (
    <Modal
      onClose={closeModal}
      icon="info-sign"
      title={`Visit ${restaurant.data.name}`}
      isOpen={isOpen}
      usePortal={true}
    >
      <Formik initialValues={initialValues} onSubmit={onVisit} enableReinitialize>
        {formik => (
          <>
            <div className={Classes.DIALOG_BODY}>
              <img className="messageImage leftImage" src={RestaurantImage} alt="restaurant" />
              <H4>What do you think about {restaurant.data.name}?</H4>
              <UserDataForm formik={formik} visited={true} isSubmitting={false} />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={closeModal} intent="danger" icon="cross">
                  Cancel
                </Button>
                <Button
                  onClick={formik.handleSubmit}
                  intent="primary"
                  icon={<Check className="icon" />}
                >
                  Visit
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default VisitRestaurantModal
