import React from "react"
import PlaceImage from "common/components/PlaceImage"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import { SRLWrapper } from "simple-react-lightbox"
import "swiper/swiper.scss"
import "swiper/components/pagination/pagination.scss"

import styles from "./detail-carousel.module.scss"

SwiperCore.use([Pagination])

const DetailCarousel = ({ items, visited }) => {
  const options = {
    settings: {
      overlayColor: "rgb(255, 255, 255)",
    },
    buttons: {
      backgroundColor: "#f2f2f2",
      iconColor: "3d6df9",
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    caption: {
      showCaption: false,
    },
  }
  return (
    <>
      {visited ? (
        <SRLWrapper options={options}>
          <Swiper
            className={styles.detailCarousel}
            spaceBetween={4}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {items.map(item => (
              <SwiperSlide key={item}>
                <PlaceImage src={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SRLWrapper>
      ) : (
        <Swiper
          className={styles.detailCarousel}
          spaceBetween={4}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {items.map(item => (
            <SwiperSlide key={item}>
              <PlaceImage src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}

export default DetailCarousel
