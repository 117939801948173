import { combineReducers } from "@reduxjs/toolkit"
import userAuth from "features/userAuth/userAuthSlice"
import userSettings from "features/userSettings/userSettingsSlice"
import restaurantsSearch from "features/restaurantsSearch/restaurantsSearchSlice"
import restaurantsQueue from "features/restaurantsQueue/restaurantsQueueSlice"
import restaurantDetails from "features/restaurantDetails/restaurantDetailsSlice"
import geolocationService from "features/geolocationService/geolocationServiceSlice"
import socialUsersList from "features/socialUsersList/socialUsersListSlice"
import socialUserDetails from "features/socialUserDetails/socialUserDetailsSlice"

const rootReducer = combineReducers({
  userAuth: userAuth.reducer,
  userSettings: userSettings.reducer,
  restaurantsSearch: restaurantsSearch.reducer,
  restaurantsQueue: restaurantsQueue.reducer,
  restaurantDetails: restaurantDetails.reducer,
  geolocationService: geolocationService.reducer,
  socialUsersList: socialUsersList.reducer,
  socialUserDetails: socialUserDetails.reducer,
})

export default rootReducer
