import React from "react"
import { Intent } from "@blueprintjs/core"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Toaster from "features/toasterService/Toaster"
import LoginPageView from "./LoginPageView"

import { loginUserAction } from "./userAuthSlice"

// -------------------------
// form logic

const initialValues = {
  email: "",
  password: "",
  remember: false,
}

function useHandleLoginSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(loginUserAction(values.email, values.password))
      history.push("/")
    } catch (e) {
      Toaster.show({
        icon: "error",
        intent: Intent.DANGER,
        message: e.message,
        timeout: 1000,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const LoginPage = () => {
  const handleLoginSubmit = useHandleLoginSubmit()
  return <LoginPageView initialValues={initialValues} onSubmit={handleLoginSubmit} />
}

export default LoginPage
