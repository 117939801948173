/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocation } from "features/geolocationService/geolocationServiceSlice"
import { useParams } from "react-router-dom"
import SocialUserDetailsPageView from "./SocialUserDetailsPageView"
import { fetchUserQueue, getUserQueue } from "./socialUserDetailsSlice"

// -------------------------
// api operations

function useFetchUserQueue(setLoading) {
  const dispatch = useDispatch()
  return async user => {
    setLoading(true)
    await dispatch(fetchUserQueue(user))
    setLoading(false)
  }
}

const SocialUserDetailsPage = () => {
  const { id } = useParams()
  const location = useSelector(getLocation)
  const [loading, setLoading] = useState(false)
  const fetchUserQueue = useFetchUserQueue(setLoading)
  const userQueue = useSelector(getUserQueue)
  const restaurants = Object.values(userQueue.restaurants)
  const queue = restaurants.filter(r => !r.visited)
  const visited = restaurants.filter(r => r.visited)
  useEffect(() => {
    fetchUserQueue(id)
  }, [])
  return (
    <SocialUserDetailsPageView
      isLoading={loading}
      queue={queue}
      visited={visited}
      location={location}
      isLocationActive={location.active && !location.hasErrors}
    />
  )
}

export default SocialUserDetailsPage
