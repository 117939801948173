import { Icon } from "leaflet"
import styles from "./restaurants-map.module.scss"

const queueIcon = new Icon({
  iconUrl: "/map/pin-neutral.svg",
  iconSize: [25, 39],
  iconAnchor: [12, 39],
  popupAnchor: [0, -37],
  className: styles.queueMarker,
})

export default queueIcon
