import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import SimpleReactLightbox from "simple-react-lightbox"

import store from "./app/store"

import "./index.scss"

const render = () => {
  const App = require("./app/App").default
  ReactDOM.render(
    <Provider store={store}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Provider>,
    document.getElementById("root"),
  )
}

render()

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render)
}
