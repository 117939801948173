import React from "react"
import ReactDOM from "react-dom"
import AuthLayout from "common/layouts/AuthLayout"
import { Intent, Checkbox, FormGroup, InputGroup, Button, Classes } from "@blueprintjs/core"
import Loader from "common/components/loaders/Loader"
import { Link } from "react-router-dom"
import { Formik } from "formik"
import validate from "validate.js"

import styles from "../../common/layouts/auth-layout.module.scss"

// -------------------------
// form validation

const constraints = {
  email: { presence: { allowEmpty: false }, email: true },
  password: { presence: true, length: { minimum: 6 } },
}

function validateForm(values) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const LoginPageView = ({ initialValues, onSubmit }) => (
  <AuthLayout title="Sign in" subtitle="Login into your account">
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={styles.form}>
          <FormGroup
            label="Email Address"
            labelInfo="*"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={touched && errors.email && Intent.DANGER}
            helperText={touched && errors.email && errors.email[0]}
          >
            <InputGroup
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={touched && errors.email && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Password"
            labelInfo="*"
            labelFor="password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.password && errors.password && Intent.DANGER}
            helperText={touched.password && errors.password}
          >
            <InputGroup
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              intent={touched.password && errors.password && Intent.DANGER}
              large
            />
          </FormGroup>
          <Checkbox
            label="Remember"
            id="remember"
            className={isSubmitting && Classes.SKELETON}
            checked={values.remember}
            onChange={handleChange}
          />
          <div className={styles.links}>
            <Link className={styles.authLink} to="/auth/password-recovery">
              Forgot password?
            </Link>
            <Link className={styles.authLink} to="/auth/register">
              Don't have an account? Register here
            </Link>
          </div>
          {isSubmitting ? (
            <Loader />
          ) : (
            ReactDOM.createPortal(
              <div className={styles.formActions}>
                <Button fill intent="primary" large onClick={handleSubmit}>
                  Sign In
                </Button>
              </div>,
              document.getElementById("action-bar"),
            )
          )}
        </form>
      )}
    </Formik>
  </AuthLayout>
)

export default LoginPageView
