import { Button, Classes, H3 } from "@blueprintjs/core"
import React from "react"
import Modal from "../../common/components/modals/Modal"
import { ReactComponent as Trash } from "assets/icons/icon-trash.svg"
import cx from "classnames"
import RestaurantImage from "assets/images/delete-restaurant.png"

const DeleteRestaurantModal = ({ isOpen, openModal, closeModal, onDelete, restaurant }) => (
  <Modal
    onClose={closeModal}
    icon="info-sign"
    title={`Delete ${restaurant.data.name}`}
    isOpen={isOpen}
    usePortal={true}
    className="bp3-dark"
  >
    <div className={cx("centered", Classes.DIALOG_BODY)}>
      <img className="messageImage" src={RestaurantImage} alt="restaurant" />
      <H3 className="centeredTitle">
        Are you sure you want to delete {restaurant.data.name} from your list?
      </H3>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={closeModal} icon="cross" className="hollowButton">
          Cancel
        </Button>
        <Button onClick={onDelete} intent="danger" icon={<Trash className="icon" />}>
          Delete
        </Button>
      </div>
    </div>
  </Modal>
)

export default DeleteRestaurantModal
