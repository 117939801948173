import React from "react"
import { Marker, Popup } from "react-leaflet"
import RestaurantPopup from "./RestaurantPopup.jsx"

const RestaurantMarkers = ({ restaurants, icon }) => {
  return restaurants.map(restaurant => {
    const { lat, lng } = restaurant.data.geometry.location
    return (
      <Marker key={restaurant.id} icon={icon} position={[lat, lng]}>
        <Popup closeButton={false}>
          <RestaurantPopup restaurant={restaurant} />
        </Popup>
      </Marker>
    )
  })
}

export default RestaurantMarkers
