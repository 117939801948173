import axios from "axios"
import { getDistance } from "geolib"
import { currentUserToken } from "api/cognito"
import { withCache, MINUTES } from "common/utils/cache"
import { API_URL } from "config/settings"

function calculateDistance(restaurant, lat, long) {
  return {
    ...restaurant,
    distance: getDistance(
      {
        latitude: restaurant.geometry.location.lat,
        longitude: restaurant.geometry.location.lng,
      },
      { latitude: lat, longitude: long },
    ),
  }
}

export const nearbyRestaurants = withCache(
  10 * MINUTES,
  [],
  async (lat, long, radius) => {
    const userToken = await currentUserToken()
    const params = { lat, long, radius }
    const response = await axios.post("/restaurants/nearby", params, {
      baseURL: API_URL,
      headers: { Authorization: userToken },
    })
    return response.data.map(r => calculateDistance(r, lat, long))
  },
  r => r.length > 3,
)

export const searchRestaurants = withCache(10 * MINUTES, [0], async (query, lat, long) => {
  const userToken = await currentUserToken()
  const params = { query, lat, long }
  const response = await axios.post("/restaurants/search", params, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data.map(r => calculateDistance(r, lat, long))
})
