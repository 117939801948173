import React, { useState, useCallback, useEffect } from "react"
import { H3 } from "@blueprintjs/core"
import Restaurants from "common/components/restaurants/Restaurants"
import styles from "./restaurant-list.module.scss"
import Loader from "common/components/loaders/Loader"
import Tabs from "common/components/tabs/Tabs"
import asian from "assets/images/asian.png"
import useRevealEffect from "../../../hooks/effects/useRevealEffect"

const RestaurantList = props => {
  const { isLocationActive, restaurantsByDistance, restaurantsByDate } = props
  const [sortMode, sortBy] = useState("distance")

  const sortedByDistance = () => sortMode === "distance"
  const getItems = () => (sortedByDistance() ? restaurantsByDistance : restaurantsByDate)
  const sortByDistance = useCallback(() => sortBy("distance"), [sortBy])
  const sortByDate = useCallback(() => sortBy("date"), [sortBy])

  const tab1 = {
    onClick: sortByDistance,
    active: sortedByDistance(),
    text: "By distance",
  }

  const tab2 = {
    onClick: sortByDate,
    active: !sortedByDistance(),
    text: "By date",
  }

  const sectionRef = useRevealEffect({ y: 10, duration: 5 })

  useEffect(() => {
    if (isLocationActive) sortByDistance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationActive])

  const items = getItems()

  return (
    <>
      <Tabs tab1={tab1} tab2={tab2} />
      {props.isLoading ? (
        <Loader className="centered" />
      ) : items.length === 0 ? (
        <div ref={sectionRef} className={styles.topSpace}>
          <img className="messageImage" src={asian} alt="asian restaurant" />
          <H3 className="centeredTitle">There are no restaurants</H3>
        </div>
      ) : (
        <Restaurants restaurants={items} className={styles.topSpace} />
      )}
    </>
  )
}

export default RestaurantList
