/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SocialUsersListPageView from "./SocialUsersListPageView"
import { fetchUsersList, getUsers } from "./socialUsersListSlice"
import { hash } from "spark-md5"

// -------------------------
// utils

function addAvatar(user) {
  return { ...user, avatar: `https://www.gravatar.com/avatar/${hash(user.email.trim())}?s=200` }
}

// -------------------------
// api operations

function useFetchUsersList(setLoading) {
  const dispatch = useDispatch()
  return async () => {
    setLoading(true)
    await dispatch(fetchUsersList())
    setLoading(false)
  }
}

// -------------------------
// component

const SocialUsersListPage = () => {
  const [loading, setLoading] = useState(false)
  const fetchUsersList = useFetchUsersList(setLoading)
  const users = useSelector(getUsers)
  const withAvatar = users.map(addAvatar)
  const isLoading = users.length === 0 && loading
  useEffect(() => {
    fetchUsersList()
  }, [])
  return <SocialUsersListPageView isLoading={isLoading} users={withAvatar} />
}

export default SocialUsersListPage
