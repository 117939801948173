import React from "react"
import Rating from "common/components/rating/Rating"
import { H3 } from "@blueprintjs/core"
import moment from "moment"

import styles from "./restaurants.module.scss"

const RestaurantMainInfo = ({
  name,
  rating,
  external_ratings_total,
  price_level,
  distance,
  visited,
  visitedAt,
}) => {
  return (
    <div className={styles.restaurantMainInfo}>
      <H3>{name}</H3>
      {visited ? <div className={styles.visited}>Visited {moment(visitedAt).fromNow()}</div> : null}
      <div className={styles.info}>
        <Rating starRating={rating} rating={rating} totalUsers={external_ratings_total} />

        <p className={styles.priceLevel}>{price_level ? "€".repeat(price_level) : "--"}</p>
        <p className={styles.distance}>{distance} m</p>
      </div>
    </div>
  )
}

export default RestaurantMainInfo
