import React from "react"
import UserMenu from "common/components/user/UserMenu"
import styles from "./top-bar.module.scss"

const TopBar = ({ title }) => {
  return (
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
      <UserMenu />
    </div>
  )
}

export default TopBar
