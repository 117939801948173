import { createSlice } from "@reduxjs/toolkit"
import * as api from "api/social"

const initialState = {
  userQueue: {
    restaurants: {},
  },
}

const socialUserDetails = createSlice({
  name: "socialUserDetails",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.userQueue = action.payload
    },
  },
})

export default socialUserDetails

// -------------------------
// selectors

export const getUserQueue = state => state.socialUserDetails.userQueue

// -------------------------
// async actions

export const fetchUserQueue = user => async dispatch => {
  dispatch(socialUserDetails.actions.setUserDetails(initialState.userQueue))
  const userQueue = await api.fetchUserQueue(user)
  dispatch(socialUserDetails.actions.setUserDetails(userQueue))
}
