import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import Toaster from "features/toasterService/Toaster"
import Loader from "common/components/loaders/Loader"

import { fetchAuthenticatedUser } from "features/userAuth/userAuthSlice"
import { Intent } from "@blueprintjs/core"

import styles from "./protectedContent.module.scss"

const ProtectedContent = ({ children }) => {
  const [isLoading, setLoading] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const recoverSession = async () => {
      try {
        await dispatch(fetchAuthenticatedUser())
        setLoading(false)
      } catch (e) {
        Toaster.show({
          icon: "lock",
          intent: Intent.WARNING,
          message: e.message || e,
          timeout: 1000,
        })
        history.push("/auth/login")
      }
    }
    recoverSession()
  }, [])
  return isLoading ? (
    <div className={styles.overlay}>
      <Loader className={styles.spinner} />
    </div>
  ) : (
    <>{children}</>
  )
}

export default ProtectedContent
