import { H6, Button } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import UserDataForm from "common/components/restaurants/UserDataForm"
import styles from "./user-data.module.scss"
import UserRating from "common/components/user-rating/UserRating"
import Loader from "common/components/loaders/Loader"
import { ReactComponent as Edit } from "../../../assets/icons/icon-edit.svg"
import useRevealEffect from "../../../hooks/effects/useRevealEffect"

const UserData = ({
  isSubmitting,
  formik,
  notes,
  rating,
  saved,
  visited,
  positiveTags,
  negativeTags,
}) => {
  const [isEditing, setEditing] = useState(false)
  useEffect(() => setEditing(false), [isSubmitting])
  const sectionRef = useRevealEffect()

  return (
    <>
      {/* isSubmitting -> spinner */}
      {isSubmitting && <Loader />}

      {/* saved and NOT editing -> show user review, notes and the [edit] button */}
      {!isSubmitting && saved && !isEditing && (
        <div className={styles.userData}>
          <div className={styles.userItem} ref={sectionRef}>
            <H6 className={styles.userTitle}>My notes:</H6>
            <p className={styles.userText}>{notes ? notes : "There are no notes"}</p>
            {visited && <UserRating rating={rating} />}
          </div>
          {visited && (
            <div className={styles.userItem} ref={sectionRef}>
              {positiveTags.length > 0 ? (
                <>
                  <p className={styles.userLabel}>Positive:</p>
                  <p className={styles.tagsPositive}>{positiveTags.join(", ")}</p>
                </>
              ) : (
                <>
                  <p className={styles.userLabel}>Positive:</p>
                  <p className={styles.noTags}>No positive tags</p>
                </>
              )}
              {negativeTags.length > 0 ? (
                <>
                  <p className={styles.userLabel}>Negative:</p>
                  <p className={styles.tagsNegative}>{negativeTags.join(", ")}</p>
                </>
              ) : (
                <>
                  <p className={styles.userLabel}>Negative:</p>
                  <p className={styles.noTags}>No negative tags</p>
                </>
              )}
            </div>
          )}

          <div className={styles.formActions}>
            <Button
              intent="primary"
              onClick={() => setEditing(true)}
              icon={<Edit className="icon" />}
            >
              Edit
            </Button>
          </div>
        </div>
      )}

      {/* saved and EDITING -> show form for editing */}
      {saved && isEditing && (
        <div className={styles.form}>
          <UserDataForm formik={formik} visited={visited} isSubmitting={isSubmitting} />
          <div className={styles.formActions}>
            <Button
              className={styles.formButton}
              icon="cross"
              intent="danger"
              onClick={() => setEditing(false)}
              loading={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              className={styles.formButton}
              icon="floppy-disk"
              intent="primary"
              onClick={formik.handleSubmit}
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default UserData
