import React from "react"

import styles from "./action-bar.module.scss"

const ActionBar = ({ leftActions, children }) => {
  return (
    <div className={styles.actionBar}>
      <div className={styles.leftActions}>{leftActions}</div>
      <div className={styles.rightActions}>{children}</div>
    </div>
  )
}

export default ActionBar
