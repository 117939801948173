import { createSlice } from "@reduxjs/toolkit"
import * as api from "api/settings"

const initialState: UserSettingsState = {
  cognitoAttributes: {
    name: "",
    email: "",
  },
  settings: {},
}

const userSettings = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setCognitoAttributes(state, action) {
      state.cognitoAttributes = action.payload
    },
    setSettings(state, action) {
      state.settings = action.payload
    },
  },
})

export default userSettings

// -------------------------
// selectors

export const getUserSettings = state => state.userSettings

// -------------------------
// async actions

export const fetchCognitoAttributes = () => async dispatch => {
  const attributes = await api.getCognitoAttributes()
  dispatch(userSettings.actions.setCognitoAttributes(attributes))
}

// export const fetchSettings = (): AppThunk => async dispatch => {
//     const settings: Settings = await api.getSettings();
//     dispatch(userSettings.actions.setSettings(settings));
// };

export const saveCognitoAttributes = attributes => async dispatch => {
  const newAttributes = await api.saveCognitoAttributes(attributes)
  dispatch(userSettings.actions.setCognitoAttributes(newAttributes))
}

// export const saveSettings = (settings: Settings): AppThunk => async dispatch => {
//     const newSettings: Settings = await api.saveSettings(settings);
//     dispatch(userSettings.actions.setSettings(newSettings));
// };
