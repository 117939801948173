import React from "react"
import cx from "classnames"
import { ReactComponent as Happy } from "../../../assets/icons/icon-happy.svg"
import { ReactComponent as Sceptic } from "../../../assets/icons/icon-sceptic.svg"
import { ReactComponent as Sad } from "../../../assets/icons/icon-sad.svg"

import styles from "./user-rating.module.scss"

const UserRating = ({ rating, pushTop }) => {
  return (
    <div
      className={cx(styles.rating, {
        [styles.pushTop]: pushTop,
      })}
    >
      {rating === -1 ? (
        <Sad className={cx(styles.ratingIcon, styles.iconError)} />
      ) : rating === 0 ? (
        <Sceptic className={cx(styles.ratingIcon, styles.iconWarning)} />
      ) : (
        <Happy className={cx(styles.ratingIcon, styles.iconSuccess)} />
      )}
    </div>
  )
}

export default UserRating
