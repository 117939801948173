export const positiveTags = [
  { label: "Good Value", value: "good-value" },
  { label: "Fancy", value: "fancy" },
  { label: "Family Friendly", value: "family-friendly" },
  { label: "Generous", value: "generous" },
  { label: "Exotic", value: "exotic" },
  { label: "Good Service", value: "good-service" },
]

export const negativeTags = [
  { label: "Expensive", value: "expensive" },
  { label: "Slow", value: "slow" },
  { label: "Not Clean", value: "not-clean" },
  { label: "Crowded", value: "crowded" },
  { label: "Bad Service", value: "bad-service" },
  { label: "Small Servings", value: "small-servings" },
]
