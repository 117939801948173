import React from "react"
import styles from "./loader.module.scss"
import cx from "classnames"

const Loader = ({ className }) => {
  return (
    <div className={cx(styles.loaderWrapper, className)}>
      <div className={styles.loaderBox}>
        <svg className={styles.loader} viewBox="0 0 50 50" width="60">
          <circle
            className={styles.path}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke="#3d6df9"
            strokeWidth="3"
          ></circle>
        </svg>
      </div>
    </div>
  )
}

export default Loader
