import React from "react"
import starsGray from "../../../assets/images/stars-gray.svg"
import starsYellow from "../../../assets/images/stars-yellow.svg"

import styles from "./rating.module.scss"

const Rating = ({ starRating, rating, totalUsers }) => {
  const percent = (starRating / 5) * 100
  return (
    <div className={styles.rating}>
      {rating ? (
        <span className={styles.ratingNumber}>{rating}</span>
      ) : (
        <span className={styles.defaultMessage}>No users rating</span>
      )}
      {rating && (
        <div className={styles.ratingBar}>
          <img className={styles.starsGray} src={starsGray} alt="stars gray" />
          <div className={styles.ratingPercent} style={{ width: `${percent}%` }}>
            <img className={styles.starsYellow} src={starsYellow} alt="stars yellow" />
          </div>
        </div>
      )}
      {rating && <p className={styles.ratingTotal}>{totalUsers}</p>}
    </div>
  )
}

export default Rating
