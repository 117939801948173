import axios from "axios"
import { currentUserToken } from "api/cognito"
import { API_URL } from "config/settings"

export async function getQueue() {
  const userToken = await currentUserToken()
  const response = await axios.get("/queue", {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function getRestaurant(id) {
  const userToken = await currentUserToken()
  const response = await axios.get(`/queue/${id}`, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function visitRestaurant(id, restaurant) {
  const userToken = await currentUserToken()
  const response = await axios.post(`/queue/${id}/visit`, restaurant, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function saveRestaurant(restaurantData) {
  const userToken = await currentUserToken()
  const response = await axios.post("/queue", restaurantData, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function updateRestaurant(id, restaurant) {
  const userToken = await currentUserToken()
  const response = await axios.put(`/queue/${id}`, restaurant, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function deleteRestaurant(id, restaurant) {
  const userToken = await currentUserToken()
  const response = await axios.delete(`/queue/${id}`, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}
