import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const LazyImage = ({ alt, src }) => (
  <div>
    <LazyLoadImage
      alt={alt}
      src={src} // use normal <img> attributes as props
      effect="blur"
    />
  </div>
)

export default LazyImage
