import React from "react"
import { Icon } from "@blueprintjs/core"

import styles from "./icon-link.module.scss"

const IconLink = ({ icon, href, text }) => {
  return (
    <div className={styles.iconLink}>
      <Icon icon={icon} />
      <a className={styles.link} href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </div>
  )
}

export default IconLink
