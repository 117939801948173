import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { H3 } from "@blueprintjs/core"
import AppLayout from "common/layouts/AppLayout"
import notFound from "assets/images/not-found.png"

import styles from "./notFoundPage.module.scss"

const NotFoundPage = () => {
  const sectionRef = useRef(null)
  return (
    <AppLayout>
      <div ref={sectionRef} className={styles.block}>
        <img className="messageImage" src={notFound} alt="not found restaurant restaurant" />
        <H3 className="centeredTitle">Nothing to see here</H3>
        <p>The page you're triying to access is empty or the address is malformed.</p>
        <Link to="/">Go back to the home page</Link>
      </div>
    </AppLayout>
  )
}

export default NotFoundPage
