import React from "react"
import AuthLayout from "common/layouts/AuthLayout"
import { AnchorButton } from "@blueprintjs/core"
import styles from "../../common/layouts/auth-layout.module.scss"

const EmailConfirmationPage = () => (
  <AuthLayout
    title="Confirm your email"
    subtitle="Please check your email inbox and follow the instructions to confirm your account."
  >
    <div className={styles.formActions}>
      <AnchorButton href="/auth/login" intent="primary" large>
        I have already confirmed my email
      </AnchorButton>
    </div>
  </AuthLayout>
)

export default EmailConfirmationPage
