import React from "react"
import IconLink from "common/components/links/IconLink"
import RestaurantMainInfo from "common/components/restaurants/RestaurantMainInfo"
import UserData from "common/components/restaurants/UserData"
import DetailCarousel from "common/components/carousel/DetailCarousel"
import defaultImage from "../../../assets/images/terrase.png"
import useRevealEffect from "../../../hooks/effects/useRevealEffect"
import { isEmpty } from "lodash"
import styles from "./restaurant-detail.module.scss"
import IconLinkLike from "../links/IconLinkLike"

const RestaurantDetail = ({ restaurant, formik, isSubmitting }) => {
  const sectionRef = useRevealEffect()

  return (
    <div className={styles.detail}>
      <div ref={sectionRef}>
        {isEmpty(restaurant.data.photos) ? (
          <img className={styles.defaultImage} src={defaultImage} alt="restaurant" />
        ) : (
          <div className={styles.detailPhotos}>
            <DetailCarousel items={restaurant.data.photos} visited />
          </div>
        )}
        <RestaurantMainInfo
          name={restaurant.data.name}
          rating={restaurant.data.rating}
          user_ratings_total={restaurant.rating}
          price_level={restaurant.data.price_level}
          distance={restaurant.data.distance}
          visited={restaurant.visited}
          visitedAt={restaurant.visitedAt}
        />

        <div className={styles.restaurantTypes}>
          {restaurant.data.types.map(item => (
            <span className={styles.typesItem} key={item}>
              {item}
            </span>
          ))}
        </div>
      </div>

      <UserData {...restaurant} formik={formik} isSubmitting={isSubmitting} />

      <div className={styles.iconList} ref={sectionRef}>
        {restaurant.data.website ? (
          <IconLink icon="globe" href={restaurant.data.website} text={restaurant.data.website} />
        ) : (
          <IconLinkLike icon="globe" text="No web available" />
        )}
        {restaurant.data.formatted_phone_number ? (
          <IconLink
            icon="phone"
            href={`tel:${restaurant.data.formatted_phone_number}`}
            text={restaurant.data.formatted_phone_number}
          />
        ) : (
          <IconLinkLike icon="phone" text="No phone available" />
        )}
        {restaurant.data.vicinity ? (
          <IconLink icon="map" href={restaurant.data.url} text={restaurant.data.vicinity} />
        ) : (
          <IconLinkLike icon="map" text="No address available" />
        )}
      </div>
    </div>
  )
}

export default RestaurantDetail
