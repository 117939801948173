import React from "react"
import AppLayout from "common/layouts/AppLayout"
import "leaflet/dist/leaflet.css"
import "leaflet/dist/leaflet.js"
import { MapContainer, TileLayer } from "react-leaflet"
import CurrentPositionMarker from "./CurrentPositionMarker"
import queueIcon from "./queueIcon"
import likedIcon from "./likedIcon"
import dislikedIcon from "./dislikedIcon"
import RestaurantMarkers from "./RestaurantMarkers"
import styles from "./restaurants-map.module.scss"

const RestaurantsMapPageView = ({
  queueByDistance,
  likedByDistance,
  dislikedByDistance,
  isLocationActive,
  location,
  isLoading,
}) => {
  // TODO: better default location (ideas: first restaurant of queue, last location stored in localstorage)
  const latLong = isLocationActive ? [location.lat, location.long] : [40.41831, -3.70275]
  // TODO: detect location changes and move the marker on the map
  return (
    <AppLayout>
      <MapContainer
        className={styles.mapContainer}
        center={latLong}
        zoom={17}
        minZoom={13}
        maxZoom={18}
        zoomControl={false}
        attributionControl={false}
        inertia={true}
        inertiaDeceleration={1500}
        touchZoom={true}
      >
        <TileLayer
          id="mapbox/streets-v11"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          /* url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png" */
        />
        {isLocationActive && <CurrentPositionMarker location={location} />}
        <RestaurantMarkers icon={queueIcon} restaurants={queueByDistance} />
        <RestaurantMarkers icon={likedIcon} restaurants={likedByDistance} />
        <RestaurantMarkers icon={dislikedIcon} restaurants={dislikedByDistance} />
      </MapContainer>
    </AppLayout>
  )
}

export default RestaurantsMapPageView
