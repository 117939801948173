import React from "react"
import ReactDOM from "react-dom"
import AuthLayout from "common/layouts/AuthLayout"
import { FormGroup, InputGroup, Button, Classes, Intent } from "@blueprintjs/core"
import { Formik } from "formik"
import { Link } from "react-router-dom"
import validate from "validate.js"
import Loader from "common/components/loaders/Loader"

import styles from "../../common/layouts/auth-layout.module.scss"

// -------------------------
// form validation

const constraints = {
  username: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false }, email: true },
  password: { presence: true, length: { minimum: 6, maximum: 10 } },
  "confirm-password": { equality: "password" },
}

function validateRegisterForm(values) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const RegisterPageView = ({ initialValues, onSubmit }) => (
  <AuthLayout title="Register" subtitle="Create new account">
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validateRegisterForm}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={styles.form}>
          <FormGroup
            label="Username"
            labelInfo="*"
            labelFor="username"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.username && errors.username && Intent.DANGER}
            helperText={touched.username && errors.username}
          >
            <InputGroup
              id="username"
              placeholder="John"
              value={values.username}
              onChange={handleChange}
              intent={touched.username && errors.username && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Email Address"
            labelInfo="*"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.email && errors.email && Intent.DANGER}
            helperText={touched.email && errors.email && errors.email[0]}
          >
            <InputGroup
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={touched.email && errors.email && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Password"
            labelInfo="*"
            labelFor="password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.password && errors.password && Intent.DANGER}
            helperText={touched.password && errors.password}
          >
            <InputGroup
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              intent={touched.password && errors.password && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Confirm Password"
            labelInfo="*"
            labelFor="confirm-password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched["confirm-password"] && errors["confirm-password"] && Intent.DANGER}
            helperText={touched["confirm-password"] && errors["confirm-password"]}
          >
            <InputGroup
              id="confirm-password"
              type="password"
              value={values["confirm-password"]}
              onChange={handleChange}
              intent={touched["confirm-password"] && errors["confirm-password"] && Intent.DANGER}
              large
            />
            <div className={styles.links}>
              <Link className={styles.authLink} to="/auth/login">
                I already have an account
              </Link>
            </div>
          </FormGroup>
          {isSubmitting ? (
            <Loader />
          ) : (
            ReactDOM.createPortal(
              <div className={styles.formActions}>
                <Button fill intent="primary" large onClick={handleSubmit}>
                  Sign Up
                </Button>
              </div>,
              document.getElementById("action-bar"),
            )
          )}
        </form>
      )}
    </Formik>
  </AuthLayout>
)

export default RegisterPageView
