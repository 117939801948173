import { createSlice } from "@reduxjs/toolkit"
import { getDistance } from "geolib"
import * as api from "api/restaurants/queue"
import Toaster from "features/toasterService/Toaster"

// -------------------------
// utility

async function withNotification(msg, thunk) {
  try {
    const ret = await thunk()
    Toaster.show({
      icon: "tick",
      intent: "success",
      message: msg,
      timeout: 1000,
    })
    return ret
  } catch (e) {
    Toaster.show({
      icon: "error",
      intent: "danger",
      message: e.message,
      timeout: 1000,
    })
  }
}

// -------------------------
// slice

const initialState = {
  restaurants: {},
}

const restaurantsQueue = createSlice({
  name: "restaurantsQueue",
  initialState,
  reducers: {
    setRestaurant(state, action) {
      const { restaurant } = action.payload
      state.restaurants[restaurant.id] = restaurant
    },
    removeRestaurant(state, action) {
      const { id } = action.payload
      delete state.restaurants[id]
    },
    setQueue(state, action) {
      const { queue } = action.payload
      return queue
    },
  },
})

export default restaurantsQueue

// -------------------------
// selectors

function getRestaurants(state, filterFn, sortFn) {
  const location = {
    latitude: state.geolocationService.lat,
    longitude: state.geolocationService.long,
  }
  const restaurants = Object.values(state.restaurantsQueue.restaurants)
  const next = restaurants.filter(filterFn)
  const withDistance = next.map(r => ({
    ...r,
    data: {
      ...r.data,
      distance: r.data
        ? getDistance(location, {
            latitude: r.data.geometry.location.lat,
            longitude: r.data.geometry.location.lng,
          })
        : undefined,
    },
  }))
  return withDistance.sort(sortFn)
}

const not = fn => (...args) => !fn(...args)
const isVisited = r => r.visited
const byDistance = (ra, rb) => ra.data.distance - rb.data.distance
const bySavedDate = (ra, rb) => {
  if (ra.savedAt === rb.savedAt) return 0
  else if (ra.savedAt > rb.savedAt) return -1
  else return 1
}
const byVisitedDate = (ra, rb) => {
  if (ra.visitedAt === rb.visitedAt) return 0
  else if (ra.visitedAt > rb.visitedAt) return -1
  else return 1
}

export const getNextRestaurantsByDistance = state => {
  return getRestaurants(state, not(isVisited), byDistance)
}

export const getNextRestaurantsByDate = state => {
  return getRestaurants(state, not(isVisited), bySavedDate)
}

export const getVisitedRestaurantsByDistance = state => {
  return getRestaurants(state, isVisited, byDistance)
}

export const getVisitedRestaurantsByDate = state => {
  return getRestaurants(state, isVisited, byVisitedDate)
}

export const getRestaurant = id => state => state.restaurantsQueue.restaurants[id] || {}

export const getQueueRestaurants = state => state.restaurantsQueue.restaurants

// -------------------------
// async actions

export const fetchQueue = () => async dispatch => {
  const queue = await api.getQueue()
  dispatch(restaurantsQueue.actions.setQueue({ queue }))
}

export const fetchRestaurant = id => async dispatch => {
  const restaurant = await api.getRestaurant(id)
  dispatch(restaurantsQueue.actions.setRestaurant({ restaurant }))
}

export const visitRestaurant = (id, restaurantPatch) => async dispatch => {
  const preparedPatch = { ...restaurantPatch, rating: Number(restaurantPatch.rating) || 0 }
  const restaurant = await api.visitRestaurant(id, preparedPatch)
  await withNotification("Restaurant visited.", () =>
    dispatch(restaurantsQueue.actions.setRestaurant({ restaurant })),
  )
}

export const saveRestaurant = restaurantData => async dispatch => {
  const restaurant = await api.saveRestaurant(restaurantData)
  await withNotification("Restaurant saved.", () =>
    dispatch(restaurantsQueue.actions.setRestaurant({ restaurant })),
  )
}

export const updateRestaurant = (id, restaurantPatch) => async dispatch => {
  const preparedPatch = { ...restaurantPatch, rating: Number(restaurantPatch.rating) || 0 }
  const restaurant = await api.updateRestaurant(id, preparedPatch)
  await withNotification("Restaurant data updated.", () =>
    dispatch(restaurantsQueue.actions.setRestaurant({ restaurant })),
  )
}

export const deleteRestaurant = id => async dispatch => {
  await api.deleteRestaurant(id)
  await withNotification("Restaurant deleted.", () =>
    dispatch(restaurantsQueue.actions.removeRestaurant({ id })),
  )
}
