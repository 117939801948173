import React from "react"
import { useDispatch } from "react-redux"
import { Intent } from "@blueprintjs/core"
import { useHistory } from "react-router-dom"
import Toaster from "features/toasterService/Toaster"
import RegisterPageView from "./RegisterPageView"

import { registerUserAction } from "./userAuthSlice"

// -------------------------
// form logic

const initialValues = {
  username: "",
  email: "",
  password: "",
  "confirm-password": "",
}

function useHandleRegisterSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await dispatch(registerUserAction(values.email, values.password, values.username))
      history.push("/auth/email-confirmation")
    } catch (e) {
      Toaster.show({
        icon: "error",
        intent: Intent.DANGER,
        message: e.message,
        timeout: 1000,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const RegisterPage = () => {
  const handleRegisterSubmit = useHandleRegisterSubmit()
  return <RegisterPageView initialValues={initialValues} onSubmit={handleRegisterSubmit} />
}

export default RegisterPage
