const region = "eu-central-1"
const identityPoolId = "eu-central-1:c8c13de0-a4ef-44bc-b71a-0b36b9076625"

const userPoolId = "eu-central-1_VireUUoZb"
const poolArn = "arn:aws:cognito-idp:eu-central-1:604851139667:userpool/eu-central-1_VireUUoZb"
const userPoolWebClientId = "2fjab7fodjhdc8vj3hvd1utstt"

const bucket = "barqueue-prototype"

const awsConfig = {
  region,
  identityPoolId,
  auth: {
    region,
    identityPoolId,
    userPoolId,
    poolArn,
    userPoolWebClientId,
  },
  storage: {
    AWSS3: {
      bucket,
      region,
      identityPoolId,
    },
  },
}

export default awsConfig
