import axios from "axios"
import { currentAuthenticatedUser, updateUserAttributes, currentUserToken } from "api/cognito"
import { API_URL } from "config/settings"

export async function getSettings() {
  const userToken = await currentUserToken()
  const response = await axios.get("/settings", {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function saveSettings(settings) {
  const userToken = await currentUserToken()
  const response = await axios.post("/settings", settings, {
    baseURL: API_URL,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function getCognitoAttributes() {
  const user = await currentAuthenticatedUser()
  return {
    name: user.attributes.name,
    email: user.attributes.email,
  }
}

export async function saveCognitoAttributes(attributes) {
  const mappedAttrs = {
    name: attributes.name,
  }
  await updateUserAttributes(mappedAttrs)
  return attributes
}
