import React from "react"
import { Icon } from "@blueprintjs/core"

import styles from "./icon-link.module.scss"

const IconLinkLike = ({ icon, text }) => {
  return (
    <div className={styles.iconLinkLike}>
      <Icon icon={icon} />
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default IconLinkLike
