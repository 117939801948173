import React from "react"
import { MenuDivider, Popover, MenuItem, Menu, Position } from "@blueprintjs/core"

import styles from "./user-menu.module.scss"

import { ReactComponent as User } from "assets/icons/icon-user2.svg"
import { ReactComponent as Logout } from "assets/icons/icon-logout.svg"
import { ReactComponent as Settings } from "assets/icons/icon-settings.svg"
import cx from "classnames"

const UserMenu = ({ blue }) => {
  return (
    <Popover
      content={
        <Menu>
          <MenuItem icon={<Settings className="icon" />} text="Settings" href="/settings" />
          <MenuDivider />
          <MenuItem icon={<Logout className="icon" />} text="Logout" href="/auth/logout" />
        </Menu>
      }
      position={Position.BOTTOM}
    >
      <button className={cx(styles.userButton, { [styles.blue]: blue })}>
        <User />
      </button>
    </Popover>
  )
}

export default UserMenu
