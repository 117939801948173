export const MINUTES = 60 * 1000
export const constantly = x => () => x

export const withCache = (ttl, params, fn, isValid = constantly(true)) => {
  return async (...args) => {
    const now = Date.now()
    const key = "cache-key:" + params.map(i => args[i]).join(":")
    const cachedValueRaw = localStorage.getItem(key)
    const cachedValue = cachedValueRaw && JSON.parse(cachedValueRaw)
    if (!cachedValue || cachedValue.stamp + ttl < now) {
      const result = await fn(...args)
      if (isValid(result)) {
        localStorage.setItem(key, JSON.stringify({ stamp: now, value: result, ttl }))
      }
      return result
    } else {
      return cachedValue.value
    }
  }
}

// keep it clean

function cleanup() {
  const now = Date.now()
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i)
    const cachedValue = localStorage.getItem(key)
    if (cachedValue.stamp + cachedValue.ttl < now) {
      localStorage.removeItem(key)
    }
  }
}

window.setInterval(10 * MINUTES, cleanup)
